import React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

type Props = {
  description?: string
  lang?: string
  meta?: []
  title: string
  pathname: string
  robots?: string
}

function SEO({
  description = "",
  lang = "en",
  meta = [],
  title,
  pathname,
  robots = "index, follow",
}: Props) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            siteImage
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`${site.siteMetadata.title} - %s`}
      meta={[
        {
          name: `robots`,
          content:
            process.env.GATSBY_ENVIRONMENT === "dev"
              ? "noindex, nofollow"
              : robots,
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: 'image',
          content: `${site.siteMetadata.siteUrl}${site.siteMetadata.siteImage}`
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: `${site.siteMetadata.siteUrl}${site.siteMetadata.siteImage}`
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },

        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          property: `twitter:image`,
          content: `${site.siteMetadata.siteUrl}${site.siteMetadata.siteImage}`
        }
      ].concat(meta)}
    >
      {pathname !== "/404/" && (
        <link
          rel="canonical"
          href={`${site.siteMetadata.siteUrl}${pathname}`}
        />
      )}
    </Helmet>
  )
}

export default SEO
